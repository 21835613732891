import { mapState } from "vuex";

export const appData = {
  data: () => ({
    endpoints: {
      search: "services/app/search",
      droppoints: "/services/nshift/droppoints",
      addresses: "/services/cart/addresses",
      shippingMethods: "/services/app/shippingMethods",
    },
    actions: {
      updateCart: "quantity-cart/cart/cart-update",
      updateShippingMethod: "quantity-cart/cart/update-shipping-method",
      updateOrder: "quantity-orders/orders/update-order",
      switchVat: "quantity-app/app/switch-vat",
      switchSku: "quantity-app/app/switch-sku",
      finalizeCart: "quantity-cart/cart/finalize",
      paymentsPay: "commerce/payments/pay",
      logIn: "quantity-account/account/login",
      selectDepartment: "quantity-account/account/select-department",
      saveFavorite: "quantity-account/favorites/save",
      removeFavorite: "quantity-account/favorites/remove",
      punchOut: "quantity-oci/oci/punch-out",
      cxmlPunchOut: "quantity-cxml/cxml/punch-out",
      generatePdf: "quantity-catalog/pdf/generate-pdf",
      pollPdf: "quantity-catalog/pdf/poll-pdf",
    },
  }),
  computed: {
    ...mapState({
      cart: (state) => state.cart.cart,
      translations: (state) => state.app.translations,
    }),
    allowPurchase: function () {
      if (!this.cart.employee) {
        return true;
      }

      if (!this.cart.useBudget && this.cart.canPurchase) {
        return true;
      }

      if (this.cart.overBudget && !this.cart.employee.allowOverBudgetPurchase) {
        return false;
      }

      if (!this.cart.canPurchase) {
        return false;
      }

      return true;
    },
    overBudget: function () {
      if (!this.cart.employee) {
        return false;
      }

      return this.cart.overBudget;
    },
    allowCheckout: function () {
      return this.cart.allowCheckout;
    },
    allowPunchout: function () {
      return this.cart.allowPunchout;
    },
  },
};
